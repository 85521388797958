export default [
  {
    img: "https://img77.uenicdn.com/image/upload/v1557982755/business/246603b8-4c6e-4f9a-8d91-147c8f3e3568/40070529-655906814795511-3871928041139601408-ojpg.jpg",
    title: "Gym & Fitness Center",
  },
  {
    img: "https://img77.uenicdn.com/image/upload/v1557982769/business/246603b8-4c6e-4f9a-8d91-147c8f3e3568/26229624-511254342594093-5937874393434008320-njpg.jpg",
    title: "Gym & Fitness Center",
  },
  {
    img: "https://img77.uenicdn.com/image/upload/v1557982790/business/246603b8-4c6e-4f9a-8d91-147c8f3e3568/40021369-655908181462041-2525793593021431808-ojpg.jpg",
    title: "Gym & Fitness Center",
  },
  {
    img: "https://img77.uenicdn.com/image/upload/v1557749951/business/246603b8-4c6e-4f9a-8d91-147c8f3e3568/IMG-20190102-WA0030jpg.jpg",
    title: "Gym & Fitness Center",
  },
  {
    img: "https://img77.uenicdn.com/image/upload/v1557749944/business/246603b8-4c6e-4f9a-8d91-147c8f3e3568/IMG-20190102-WA0028jpg.jpg",
    title: "Gym & Fitness Center",
  },
  {
    img: "https://img77.uenicdn.com/image/upload/v1557749940/business/246603b8-4c6e-4f9a-8d91-147c8f3e3568/IMG-20190102-WA0025jpg.jpg",
    title: "Gym & Fitness Center",
  },
  {
    img: "https://images.unsplash.com/photo-1518611012118-696072aa579a",
    title: "Gym & Fitness Center",
  },
  {
    img: "https://images.unsplash.com/photo-1571731956672-f2b94d7dd0cb",
    title: "Gym & Fitness Center",
  },
  {
    img: "https://images.unsplash.com/photo-1599058917212-d750089bc07e",
    title: "Gym & Fitness Center",
  },
  {
    img: "https://images.unsplash.com/photo-1518310383802-640c2de311b2",
    title: "Gym & Fitness Center",
  },
  {
    img: "https://images.unsplash.com/photo-1434682772747-f16d3ea162c3",
    title: "Gym & Fitness Center",
  },
  {
    img: "https://images.unsplash.com/photo-1532029837206-abbe2b7620e3",
    title: "Gym & Fitness Center",
  },
];
